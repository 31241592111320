<template>
  <div class="modal">
    <div class="modal__container">
      <!-- REGISTRATION -->
      <div v-if="isShowRegistration" class="club-registration-modal">
        <button class="club-registration-modal__close" @click="close"></button>
        <div class="club-registration-modal__title">{{ $t("fillForm") }}</div>
        <div class="club-registration-modal__descr">
          {{ $t("fillFormText") }}
        </div>
        <validation-observer ref="formObserver">
          <form
            class="club-registration-modal__wrapper is-desktop"
            @submit.prevent="sendPhone"
          >
            <div class="club-registration-modal__cart input">
              <validation-provider
                v-slot="{ errors }"
                rules="required|length:19"
              >
                <input
                  v-model="user.cartNumber"
                  type="text"
                  class="input-field"
                  v-mask="'#### #### #### ####'"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "length"
                    ? $t("enterCartNumber")
                    : $t("cardNumberShould")
                }}</label>
                <label
                  v-if="!errors.length && !errorsFromBackend.cardNumber"
                  class="input-label"
                >
                  {{ $t("cardNumber") }}</label
                >
                <label
                  v-if="errorsFromBackend.cardNumber"
                  class="input-label error"
                  >{{ errorsFromBackend.cardNumber }}</label
                >
              </validation-provider>
            </div>

            <div class="club-registration-modal__number input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input
                  ref="userPhone"
                  v-model="user.phone"
                  type="text"
                  v-mask="'(###) ### ## ##'"
                  class="input-field input-phone"
                  title="Введите номер"
                  @focus="focusPhone"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterPhone")
                    : $t("phoneIncorrectly")
                }}</label>
                <label v-else class="input-label">
                  {{ $t("phoneNumber") }}</label
                >
                <span v-if="isShowPhoneSpan" class="input-phone-span">
                  +7
                </span>
              </validation-provider>
            </div>

            <div class="club-registration-modal__gender">
              <div class="club-registration-modal__gender-title">
                {{ $t("gender") }}
              </div>
              <div class="club-registration-modal__gender-wrapper">
                <button
                  class="club-registration-modal__male"
                  :class="{ 'is-active': user.gender === 'male' }"
                  @click.prevent="gerGender('male')"
                >
                  {{ $t("male") }}
                </button>
                <button
                  class="club-registration-modal__female"
                  @click.prevent="gerGender('female')"
                  :class="{ 'is-active': user.gender === 'female' }"
                >
                  {{ $t("female") }}
                </button>
              </div>
            </div>

            <div class="club-registration-modal__name input">
              <validation-provider v-slot="{ errors }" rules="required|alpha">
                <input
                  ref="first_name"
                  v-model="user.first_name"
                  type="text"
                  class="input-field"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterName")
                    : $t("nameIncorrectly")
                }}</label>
                <label v-else class="input-label"> {{ $t("name") }}</label>
              </validation-provider>
            </div>

            <div class="club-registration-modal__firstname input">
              <validation-provider v-slot="{ errors }" rules="required|alpha">
                <input
                  ref="last_name"
                  v-model="user.last_name"
                  type="text"
                  class="input-field"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterSurname")
                    : $t("lastNameIncorrectly")
                }}</label>
                <label v-else for="" class="input-label">
                  {{ $t("surname") }}</label
                >
              </validation-provider>
            </div>

            <div class="club-registration-modal__patronymic input">
              <validation-provider v-slot="{ errors }" rules="required|alpha">
                <input
                  ref="patronymic"
                  v-model="user.patronymic"
                  type="text"
                  class="input-field"
                />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterPatronymic")
                    : $t("surnameIncorrectly")
                }}</label>
                <label v-else for="" class="input-label">
                  {{ $t("patronymic") }}</label
                >
              </validation-provider>
            </div>

            <div class="club-registration-modal__birthday input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input
                  ref="birthday"
                  v-model="user.birthday"
                  type="text"
                  class="input-field"
                  v-mask="'##.##.####'"
                />
                <label v-if="!birthday_error" class="input-label error">
                  {{ $t("canParticipate") }}
                </label>
                <label
                  v-if="errors.length && birthday_error"
                  class="input-label error"
                >
                  {{ $t("enterBirth") }}
                </label>
                <label
                  v-else
                  for=""
                  class="input-label"
                  :class="{ none: !birthday_error }"
                  >{{ $t("dateBirth") }}</label
                >
              </validation-provider>
            </div>

            <div class="club-registration-modal__region input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input v-model="user.region" type="text" class="input-field" />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required" ? $t("enterRegion") : "Регион"
                }}</label>
                <label v-else for="" class="input-label">{{
                  $t("region")
                }}</label>
              </validation-provider>
            </div>

            <div class="club-registration-modal__email input">
              <validation-provider v-slot="{ errors }" rules="required">
                <input v-model="user.email" type="email" class="input-field" />
                <label v-if="errors.length" class="input-label error">{{
                  errors[0] === "required"
                    ? $t("enterEmail")
                    : $t("emailIncorrectly")
                }}</label>
                <label v-else for="" class="input-label">E-mail</label>
              </validation-provider>
            </div>

            <button class="club-registration-modal__button" type="submit">
              {{ $t("send") }}
            </button>
          </form>
        </validation-observer>
      </div>
      <div v-if="isShowSms" class="club-registration-modal">
        <button class="club-registration-modal__close" @click="close"></button>
        <div class="club-registration-modal__title">{{ $t("enterSms") }}</div>
        <div class="club-registration-modal__descr">
          {{ $t("sentMessage") }} +7 {{ user.phone }}
        </div>
        <form class="club-sms">
          <input
            v-model="sms.code_1"
            ref="firstNumber"
            maxlength="1"
            type="text"
            class="club-sms-input"
            @keyup="addInput"
            :class="{ focus: sms.code_1.length > 0 }"
          />
          <input
            ref="secondNumber"
            v-model="sms.code_2"
            maxlength="1"
            type="text"
            class="club-sms-input"
            @keyup="addInput"
            :class="{ focus: sms.code_2.length > 0 }"
          />
          <input
            v-model="sms.code_3"
            ref="thirdNumber"
            maxlength="1"
            type="text"
            class="club-sms-input"
            @keyup="addInput"
            :class="{ focus: sms.code_3.length > 0 }"
          />
          <input
            v-model="sms.code_4"
            ref="fourthNumber"
            maxlength="1"
            type="text"
            class="club-sms-input"
            @keyup="addInput"
            :class="{ focus: sms.code_4.length > 0 }"
          />
        </form>
        <div v-if="isShowLoader" class="loader-mini__container">
          <div class="loader-mini"></div>
        </div>
        <button
          v-if="isShowAgainBtn"
          class="club-sms-btn"
          @click="sendPhoneAgain"
        >
          {{ $t("sendCodeAgain") }}
        </button>
        <div v-else class="club-registration-modal__descr">
          <p v-if="$i18n.locale === 'ru'">
            {{ $t("sendCodeAgainThrough") }} {{ timer }}
          </p>
          <p v-else>{{ timer }} {{ $t("sendCodeAgainThrough") }}</p>
        </div>

        <div
          class="club-registration-modal__descr"
          v-html="$t('notReceivingSMS')"
        ></div>
      </div>
      <div v-if="isShowCheckMail" class="club-modal">
        <button class="club-modal__close" @click="close"></button>
        <div class="club-modal__title">{{ $t("congratulations") }}</div>
        <div class="club-modal__descr">
          {{ $t("informationAboutRegistration") }}
        </div>
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/club-page/modal.png"
          alt="modal"
          class="club-modal__img"
        />
        <img
          v-else
          src="@/assets/img/club-page/modal-kz.png"
          alt="modal"
          class="club-modal__img"
        />
        <button class="club-modal__button">{{ $t("resendEmail") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/helpers";
import {
  sendQuestionnairePhone,
  sendQuestionnaireRegistration,
  sendQuestionnaireBind,
  sendQuestionnaireProfile,
  sendQuestionnaireRefresh,
} from "@/api/club";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "AppModalRegistration",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        cartNumber: "",
        gender: "",
        phone: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        birthday: "",
        region: "",
        email: "",
      },
      birthday_error: true,
      timer: 60,
      sms: {
        code_1: "",
        code_2: "",
        code_3: "",
        code_4: "",
      },
      currentRefs: 0,
      isShowLoader: false,
      isShowSms: false,
      isShowRegistration: true,
      isShowAgainBtn: false,
      isShowCheckMail: false,
      authToken: null,
      errorsFromBackend: {
        cardNumber: null,
      },

      isShowPhoneSpan: false,
    };
  },
  watch: {
    "user.cartNumber": {
      deep: true,
      handler() {
        this.errorsFromBackend.cardNumber = null;
      },
    },
  },
  computed: {
    smsInputRefs() {
      return [
        this.$refs.firstNumber,
        this.$refs.secondNumber,
        this.$refs.thirdNumber,
        this.$refs.fourthNumber,
      ];
    },
    getPhone() {
      return this.user.phone.replace(/[^0-9]/g, "");
    },
  },
  mounted() {
    if (this.timer === 0) {
      this.isShowAgainBtn = true;
    }
  },
  methods: {
    focusPhone() {
      this.isShowPhoneSpan = true;
    },
    close() {
      this.$emit("close");
      document.querySelector("body").classList.remove("no-scroll");
    },
    gerGender(item) {
      this.user.gender = item;
    },
    sumCode(items) {
      let sum = "";
      for (let item of Object.values(items)) {
        sum += item;
      }
      return sum;
    },
    addInput(e) {
      if (e.code !== "Backspace") {
        if (this.currentRefs < 3) {
          this.currentRefs++;
          this.smsInputRefs[this.currentRefs]?.focus();
        } else if (this.sumCode(this.sms).length === 4) {
          this.sendCode();
        }
      } else {
        if (this.currentRefs > 0) {
          this.currentRefs--;
          this.smsInputRefs[this.currentRefs]?.focus();
        }
      }
    },
    deleteInput(e) {
      if (e.code === "Backspace") {
        this.currentRefs--;
        this.smsInputRefs[this.currentRefs]?.focus();
      }
    },
    sendPhone() {
      this.$refs.formObserver.validate().then((success) => {
        let year = new Date().getFullYear();
        if (year - this.$refs["birthday"].value.substr(6) >= 14) {
          this.birthday_error = true;
        } else {
          this.birthday_error = false;
        }

        if (success && this.birthday_error)
          sendQuestionnairePhone({
            phone: this.user.phone.replace(/[^0-9]/g, ""),
          }).then((res) => {
            if (res.status === 200) {
              this.isShowRegistration = false;
              this.isShowSms = true;
              if (this.isShowSms) {
                setInterval(() => {
                  if (this.timer != 0) {
                    this.timer--;
                  } else {
                    this.isShowAgainBtn = true;
                  }
                }, 1000);
              }
            }
          });
      });
    },
    sendPhoneAgain() {
      this.isShowAgainBtn = false;
      this.timer = 60;
      sendQuestionnairePhone({
        phone: this.user.phone.replace(/[^0-9]/g, ""),
      }).then((res) => {
        if (res.status === 200) {
          this.isShowRegistration = false;
          this.isShowSms = true;
          if (this.isShowSms) {
            setInterval(() => {
              if (this.timer != 0) {
                this.timer--;
              } else {
                this.isShowAgainBtn = true;
              }
            }, 1000);
          }
        }
      });
    },
    async sendCode() {
      const requestRegistration = await sendQuestionnaireRegistration({
        code: Number(this.sumCode(this.sms)),
        phone: this.user.phone.replace(/[^0-9]/g, ""),
      });
      this.isShowLoader = true;

      const requestRefresh = await sendQuestionnaireRefresh({
        refresh_token: requestRegistration.data.refresh_token,
      });

      if (requestRefresh.status === 200) {
        const formatedDate =
          JSON.parse(JSON.stringify(this.user.birthday))
            .split(".")
            .reverse()
            .join("-") + "T11:57:42.958Z";
        try {
          await sendQuestionnaireProfile({
            user: { ...this.user, birthday: formatedDate },
            auth_token: requestRefresh.data.auth_token,
          });

          await sendQuestionnaireBind({
            card_number: Number(this.user.cartNumber.replace(/\s+/g, "")),
            auth_token: requestRefresh.data.auth_token,
          });

          this.isShowSms = false;
          this.isShowCheckMail = true;
          eventBus.$emit("gtm", {
            event: "magnum_club_create",
          });
        } catch (e) {
          this.isShowRegistration = true;
          this.isShowSms = false;
          this.errorsFromBackend.cardNumber = "Карта уже зарегистрирована";
          this.sms = {
            code_1: "",
            code_2: "",
            code_3: "",
            code_4: "",
          };
          this.currentRefs = 0;
        }
      }
    },
  },
};
</script>
