import axiosOriginal from "@/plugins/axiosUninterseptor";

export const sendQuestionnairePhone = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/checkPhone`,
    data
  );
  return response;
};

export const sendQuestionnaireCart = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/bind`,
    data
  );
  return response;
};

export const sendQuestionnaireRegistration = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/registration`,
    data
  );
  return response;
};

export const sendQuestionnaireRefresh = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/refresh`,
    data
  );
  return response;
};

export const sendQuestionnaireBind = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/bind`,
    { card_number: data.card_number },
    {
      headers: {
        // eslint-disable-next-line prettier/prettier
        Authorization: "Bearer " + data.auth_token,
      },
    }
  );
  return response;
};

export const sendQuestionnaireProfile = async (data) => {
  const response = await axiosOriginal.post(
    `/magnumclub-registration/profile`,
    {
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      patronymic: data.user.patronymic,
      birthday: data.user.birthday,
      gender: data.user.gender,
      phone: data.user.phone.replace(/[^0-9]/g, "").slice(1),
      email: data.user.email,
    },
    {
      headers: {
        // eslint-disable-next-line prettier/prettier
        Authorization: "Bearer " + data.auth_token,
      },
    }
  );
  return response;
};
